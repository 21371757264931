import type {
  ChannelBundleDto_BundlePageView,
  ChannelDto,
  ContinueWatchingEpisodeDto_ContinueWatchingPageView,
  ContinueWatchingVodDto_ContinueWatchingPageView,
  LiveEventDto_LiveEventPageView,
  PartnerProductDto_SlicedPartnerProductView,
  TvShowBundleDto_BundlePageView,
  TvShowDto_TvShowPageView,
  VodBundleDto_BundlePageView,
  VodDto,
} from '@setplex/wbs-api-types'
import * as v from 'valibot'
import { type ApiType } from '../../../shared/schemas'

const DateTimeSchema = v.pipe(
  v.string(),
  v.isoTimestamp(),
  v.transform((t) => new Date(t))
)

// LIVE_EVENT - Events
// e.g. /api/v3/live-events?status=LIVE_SOON
export const LiveEventItemSchema = v.object({
  id: v.number(),
  externalId: v.nullish(v.string()),
  name: v.string(),
  description: v.nullish(v.string()),
  ageRatings: v.nullish(v.string()),
  free: v.nullish(v.boolean(), false),
  status: v.picklist(['LIVE', 'SOON', 'LIVE_SOON', 'ENDED']),
  startTime: v.nullish(DateTimeSchema),
  endTime: v.nullish(DateTimeSchema),
  updated: v.nullish(DateTimeSchema),
  locked: v.nullish(v.boolean(), false),
  verticalPosterUrl: v.nullish(v.pipe(v.string(), v.url())),
  videoPosterUrl: v.nullish(v.pipe(v.string(), v.url())),
  recorded: v.nullish(v.boolean(), false),
  recordAvailableToTime: v.nullish(DateTimeSchema),
  liveRewind: v.nullish(v.boolean(), false),
  // priceSettings?: Array<ContentPriceSettingsDto_LiveEventPageView>
  // purchaseInfo?: ContentPurchaseInfoDto_LiveEventPageView
}) satisfies ApiType<LiveEventDto_LiveEventPageView, any>

// MIXED (with specified content type) - Continue Watching
// e.g. /api/v3/continue-watching
const ContinueWatchingVodItemSchema = v.object({
  contentType: v.literal('VOD'),
  stoppedTime: v.nullish(v.number()),
  contentId: v.number(),
  contentName: v.string(),
  backgroundImageUrl: v.nullish(v.pipe(v.string(), v.url())),
  landscapeImageUrl: v.nullish(v.pipe(v.string(), v.url())),
  portraitImageUrl: v.nullish(v.pipe(v.string(), v.url())),
  length: v.nullish(v.string()),
  description: v.nullish(v.string()),
  favorite: v.nullish(v.boolean(), false),
  blockedByAcl: v.nullish(v.boolean(), false),
  locked: v.nullish(v.boolean(), false),
  free: v.nullish(v.boolean(), false),
  // priceSettings?: Array<ContentPriceSettingsDto_ContinueWatchingPageView>
  // purchaseInfo?: ContentPurchaseInfoDto_ContinueWatchingPageView
}) satisfies ApiType<ContinueWatchingVodDto_ContinueWatchingPageView, any>

const ContinueWatchingEpisodeItemSchema = v.object({
  contentType: v.literal('EPISODE'),
  stoppedTime: v.nullish(v.number()),
  contentId: v.number(),
  contentName: v.string(),
  backgroundImageUrl: v.nullish(v.pipe(v.string(), v.url())),
  landscapeImageUrl: v.nullish(v.pipe(v.string(), v.url())),
  portraitImageUrl: v.nullish(v.pipe(v.string(), v.url())),
  length: v.nullish(v.string()),
  description: v.nullish(v.string()),
  seasonId: v.nullish(v.number()),
  tvShowId: v.number(),
  seasonName: v.nullish(v.string()),
  tvShowName: v.string(),
  seasonNumber: v.nullish(v.string()),
  episodeNumber: v.nullish(v.string()),
  isWithSeason: v.nullish(v.boolean(), false),
  seasonCount: v.nullish(v.number()),
  year: v.nullish(v.number()),
  favorite: v.nullish(v.boolean(), false),
  blockedByAcl: v.nullish(v.boolean(), false),
  locked: v.nullish(v.boolean(), false),
  free: v.nullish(v.boolean(), false),
  withSeason: v.nullish(v.boolean(), false),
  // episodePriceSettings?: Array<ContentPriceSettingsDto_ContinueWatchingPageView>
  // seasonPriceSettings?: Array<ContentPriceSettingsDto_ContinueWatchingPageView>
  // tvShowPriceSettings?: Array<ContentPriceSettingsDto_ContinueWatchingPageView>
  // purchaseInfo?: ContentPurchaseInfoDto_ContinueWatchingPageView
}) satisfies ApiType<ContinueWatchingEpisodeDto_ContinueWatchingPageView, any>

export const MixedItemSchema = v.variant('contentType', [
  ContinueWatchingVodItemSchema,
  ContinueWatchingEpisodeItemSchema,
])

// PARTNER_PRODUCT - Partner Products
// e.g. /api/v3/partner-product
export const PartnerProductItemSchema = v.object({
  id: v.number(),
  externalId: v.nullish(v.string()),
  name: v.string(),
  description: v.nullish(v.string()),
  externalProductId: v.nullish(v.string()),
  coverImageUrl: v.nullish(v.pipe(v.string(), v.url())),
  partnerProvider: v.picklist(['iQIYI', 'WeTV']),
  partnersProgramType: v.nullish(v.picklist(['REDIRECT']), 'REDIRECT'),
  free: v.nullish(v.boolean(), false),
  isActive: v.nullish(v.boolean(), false),
  // priceSettings?: Array<PartnerProductPricingDto_SlicedPartnerProductView>
  // purchaseInfo?: PartnerProductAccessDto_SlicedPartnerProductView
}) satisfies ApiType<PartnerProductDto_SlicedPartnerProductView, any>

// TV_BUNDLE - Live Channels Extras
// e.g. /api/v3/bundles/channels?sortOrder=asc
export const ChannelsBundleItemSchema = v.object({
  id: v.number(),
  externalId: v.nullish(v.string()),
  name: v.string(),
  description: v.nullish(v.string()),
  coverImageUrl: v.nullish(v.pipe(v.string(), v.url())),
  contentQuantity: v.nullish(v.number()),
  updatedTime: v.nullish(DateTimeSchema),
  // priceSettings?: Array<BundlePricingDto_BundlePageView>
  // purchaseInfo?: ContentPurchaseInfoDto_BundlePageView
}) satisfies ApiType<ChannelBundleDto_BundlePageView, any>

// TV_CHANNEL - Featured Live Channels, Live Channels You May Like, Recently Watched Live Channels, Favorite Live Channels, TV Channel Categories
// e.g. /api/v3/channels?type=TV&categoryId=90000698
// e.g. /api/v3/carousels/featured/content?types=TV_CHANNEL
// e.g. /api/v3/channels/recently-watched
export const ChannelItemSchema = v.object({
  contentType: v.nullish(v.literal('TV_CHANNEL'), 'TV_CHANNEL'),
  id: v.number(),
  externalId: v.nullish(v.string()),
  name: v.string(),
  logoUrl: v.nullish(v.pipe(v.string(), v.url())),
  epgId: v.nullish(v.string()),
  channelNumber: v.number(),
  locked: v.nullish(v.boolean(), false),
  liveRewind: v.nullish(v.boolean(), false),
  orderType: v.nullish(v.picklist(['First', 'Default', 'Last']), 'Default'),
  resolution: v.nullish(v.picklist(['HD', 'SD', 'FHD', '_2K', '_4K']), 'HD'),
  categoryIds: v.nullish(v.array(v.number()), []),
  updated: v.nullish(DateTimeSchema),
  chatAllowed: v.nullish(v.boolean(), false),
  blockedByAcl: v.nullish(v.boolean(), false),
  favorite: v.nullish(v.boolean(), false),
  free: v.nullish(v.boolean(), false),
  // priceSettings?: Array<ContentPriceSettingsDto>
  // purchaseInfo?: ContentPurchaseInfoDto
}) satisfies ApiType<ChannelDto, any>

// TV_SHOW_BUNDLE - Series Extras
// e.g. /api/v3/bundles/tv-shows?sortOrder=asc
export const TvShowsBundleItemSchema = v.object({
  id: v.number(),
  externalId: v.nullish(v.string()),
  name: v.string(),
  description: v.nullish(v.string()),
  coverImageUrl: v.nullish(v.pipe(v.string(), v.url())),
  contentQuantity: v.nullish(v.number()),
  updatedTime: v.nullish(DateTimeSchema),
  // priceSettings?: Array<BundlePricingDto_BundlePageView>
  // purchaseInfo?: ContentPurchaseInfoDto_BundlePageView
}) satisfies ApiType<TvShowBundleDto_BundlePageView, any>

// TV_SHOW - Featured Series, Favorite Series, Last Added Series, Recently Updated, TV Show Categories, TV Show Subcategories
// e.g. /api/v3/tvshows?categoryId=90001127
// e.g. /api/v3/carousels/featured/content?types=TV_SHOW
export const TvShowItemSchema = v.object({
  contentType: v.nullish(v.literal('TV_SHOW'), 'TV_SHOW'),
  id: v.number(),
  externalId: v.nullish(v.string()),
  name: v.string(),
  description: v.nullish(v.string()),
  directors: v.nullish(v.string()),
  stars: v.nullish(v.string()),
  year: v.nullish(v.number()),
  ageRatings: v.nullish(v.string()),
  landscapeImageUrl: v.nullish(v.pipe(v.string(), v.url())),
  portraitImageUrl: v.nullish(v.pipe(v.string(), v.url())),
  backgroundImageUrl: v.nullish(v.pipe(v.string(), v.url())),
  locked: v.nullish(v.boolean(), false),
  isWithSeason: v.nullish(v.boolean(), false),
  isTrailerExists: v.nullish(v.boolean(), false),
  seasonCount: v.nullish(v.number()),
  episodeCount: v.nullish(v.number()),
  updated: v.nullish(DateTimeSchema),
  blockedByAcl: v.nullish(v.boolean(), false),
  favorite: v.nullish(v.boolean(), false),
  free: v.nullish(v.boolean(), false),
  purchasedSeasonsCount: v.nullish(v.number()),
  purchasedEpisodesCount: v.nullish(v.number()),
  withSeason: v.nullish(v.boolean(), false),
  trailerExists: v.nullish(v.boolean(), false),
  // priceSettings?: Array<ContentPriceSettingsDto_TvShowPageView>
  // purchaseInfo?: ContentPurchaseInfoDto_TvShowPageView
}) satisfies ApiType<TvShowDto_TvShowPageView, any>

// VOD_BUNDLE - Movies Extras
// e.g. /api/v3/bundles/vods?sortOrder=asc
export const VodsBundleItemSchema = v.object({
  id: v.number(),
  externalId: v.nullish(v.string()),
  name: v.string(),
  description: v.nullish(v.string()),
  coverImageUrl: v.nullish(v.pipe(v.string(), v.url())),
  contentQuantity: v.nullish(v.number()),
  updatedTime: v.nullish(DateTimeSchema),
  // priceSettings?: Array<BundlePricingDto_BundlePageView>
  // purchaseInfo?: ContentPurchaseInfoDto_BundlePageView
}) satisfies ApiType<VodBundleDto_BundlePageView, any>

// VOD - Featured Movies, Movies You May Like, Favorite Movies, Las Added Movies, VOD Categories, VOD Subcategories
// e.g. /api/v3/vods?categoryId=90000746
// e.g. /api/v3/carousels/featured/content?types=VOD
// e.g. /api/v3/vods?only-recommended=true
export const VodItemSchema = v.object({
  contentType: v.nullish(v.literal('VOD'), 'VOD'),
  id: v.number(),
  externalId: v.nullish(v.string()),
  name: v.string(),
  description: v.nullish(v.string()),
  directors: v.nullish(v.string()),
  stars: v.nullish(v.string()),
  year: v.nullish(v.number()),
  ageRatings: v.nullish(v.string()),
  imageUrl: v.nullish(v.pipe(v.string(), v.url())),
  imageBackgroundUrl: v.nullish(v.pipe(v.string(), v.url())),
  imageHorizontalUrl: v.nullish(v.pipe(v.string(), v.url())),
  length: v.nullish(v.string()),
  resolution: v.nullish(v.picklist(['HD', 'SD', 'FHD', '_2K', '_4K']), 'HD'),
  orderType: v.nullish(v.picklist(['FIRST', 'DEFAULT', 'LAST']), 'DEFAULT'),
  trailerPresent: v.nullish(v.boolean(), false),
  blockedByAcl: v.nullish(v.boolean(), false),
  watched: v.nullish(v.boolean(), false),
  favorite: v.nullish(v.boolean(), false),
  locked: v.nullish(v.boolean(), false),
  downloadable: v.nullish(v.boolean(), false),
  updated: v.nullish(DateTimeSchema),
  tags: v.nullish(v.array(v.string()), []),
  publishTime: v.nullish(DateTimeSchema),
  stoppedTime: v.nullish(v.number()),
  free: v.nullish(v.boolean(), false),
  // priceSettings?: Array<ContentPriceSettingsDto>
  // purchaseInfo?: ContentPurchaseInfoDto
}) satisfies ApiType<VodDto, any>
