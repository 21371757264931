import type { BaseIssue, BaseSchema } from 'valibot'
import * as v from 'valibot'

// generic successful response schema
export const GenericSuccessResponseSchema = v.object({
  status: v.literal('OK'),
  payload: v.nonNullish(v.unknown()),
})

// generic errornous response schema
export const GenericErrorResponseSchema = v.object({
  status: v.literal('ERROR'),
  errorCode: v.pipe(v.union([v.string(), v.number()]), v.transform(String)),
  message: v.nullish(v.string()),
})

// generic response schema
export const GenericResponseSchema = v.variant('status', [
  GenericSuccessResponseSchema,
  GenericErrorResponseSchema,
])

// generic page info schema (page without content)
export const GenericPageInfoSchema = v.object({
  content: v.array(v.unknown()),
  first: v.boolean(),
  last: v.boolean(),
  number: v.number(),
  size: v.number(),
  totalElements: v.number(),
  totalPages: v.number(),
})

// generic slice info schema (slice without content)
export const GenericSliceInfoSchema = v.object({
  content: v.array(v.unknown()),
  first: v.boolean(),
  last: v.boolean(),
  number: v.number(),
  size: v.number(),
})

/**
 * Creates a successful response schema for a given payload schema
 */
export function response<
  const TPayload extends BaseSchema<unknown, unknown, BaseIssue<unknown>>
>(payload: TPayload) {
  return v.object({
    status: v.literal('OK'),
    payload,
  })
}

/**
 * Creates a schema for a page of items, in v3 WBS API
 */
export function page<
  const TContent extends BaseSchema<unknown, unknown, BaseIssue<unknown>>
>(content: TContent) {
  return v.object({
    ...GenericPageInfoSchema.entries,
    content: v.array(content),
  })
}

/**
 * Creates a schema for a slice of items, in v3 WBS API
 * Technically, a slice is the same as a page, but without the `totalElements` and `totalPages` fields
 */
export function slice<
  const TContent extends BaseSchema<unknown, unknown, BaseIssue<unknown>>
>(content: TContent) {
  return v.object({
    ...GenericSliceInfoSchema.entries,
    content: v.array(content),
  })
}
